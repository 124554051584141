$primary: #007D00;

@charset "utf-8";
/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/montserrat-v24-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../webfonts/montserrat-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../webfonts/montserrat-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/montserrat-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../webfonts/montserrat-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../webfonts/montserrat-v24-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  

$family-sans-serif: 'Montserrat', sans-serif;
$primary: #188eac !default;

$tabs-link-active-color: $primary;
$tabs-link-active-border-bottom-color: $primary;

$modal-content-width: 800px;

@import "../node_modules/bulma/bulma.sass";
$hero-darken: $dark !default;

@import "layout";
@import "syntax";
@import "showcase";
@import "../node_modules/bulma-block-list/src/block-list.scss";

.gh-sponsor {
  color: #ea4aaa;
}